<template>
  <div class="app">
    <div ref="container" class="keen-slider">
      <div class="keen-slider__slide number-slide1">
        <img src="/img/slide1.jpg" />
      </div>
      <div class="keen-slider__slide number-slide2">
        <img src="/img/slide2.jpg" />
      </div>
      <div class="keen-slider__slide number-slide3">
        <img src="/img/slide3.jpg" />
      </div>
      <div class="keen-slider__slide number-slide4">
        <img src="/img/slide4.jpg" />
      </div>
      <div class="keen-slider__slide number-slide5">
        <img src="/img/slide5.jpg" />
      </div>
      <div class="keen-slider__slide number-slide6">
        <img src="/img/slide6.jpg" />
      </div>
      <div class="keen-slider__slide number-slide7">
        <img src="/img/slide7.jpg" />
      </div>
      <div class="keen-slider__slide number-slide8">
        <img src="/img/slide8.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import { useKeenSlider } from "keen-slider/vue.es";
import "keen-slider/keen-slider.min.css";

export default {
  setup() {
    const [container] = useKeenSlider(
      {
        loop: true,
      },
      [
        (slider) => {
          let timeout;
          let mouseOver = false;
          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
              slider.next();
            }, 2000);
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on("dragStarted", clearNextTimeout);
          slider.on("animationEnded", nextTimeout);
          slider.on("updated", nextTimeout);
        },
      ]
    );
    return { container };
  },
};
</script>

<style>

body {
  margin: 0;
  padding: 0;
  background: #000;}
.app {
   /* Темно-серый фон */
  margin: 0;
  display: flex;
  align-items: center; /* Выравнивание по вертикали */
  justify-content: center; /* Выравнивание по горизонтали */
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-size: cover;
background-position: center;
}
.app img {
height: 100%;
}
[class^="number-slide"],
[class*=" number-slide"] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  height: 650px;
  max-height: 100vh;
}
</style>
